import type { Address, Job, JobType, JobTypeRequiredFields, Trip } from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useLazyQuery } from '@apollo/client'
import { Spin, Table } from 'antd'
import { isEmpty, startCase, uniq } from 'lodash'
import { v4 as uuidV4 } from 'uuid'

import {
  COMPANIES_QUERY,
  defaultColumns,
  formatArrayString
} from '@/components/Booking/NewBookingForm/ConfirmationSubForm/JobsTable/helper'
import { mergeAddressShort } from '@/components/Transport/Utils/jobHelper'
import useJobTypes from '@/hooks/useJobTypes'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'

const JobsTable = memo(({ client, form, step }: any) => {
  const [columns, setColumns] = useState<any>(defaultColumns)
  const [addressesMap, setAddressesMap] = useState(new Map())

  const { jobTypes, requestJobTypes } = useJobTypes(client)

  const dataSource = useMemo(() => form.getFieldValue('jobs') || [], [form])

  const [getCompanies, { loading, error }] = useLazyQuery(COMPANIES_QUERY, {
    client,
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data?.companies?.rows) {
        const companies = data.companies.rows.reduce((acc: any, company: any) => {
          company.addresses.forEach((address: Address) => {
            acc.set(address.uuid, mergeAddressShort(address))
          })

          return acc
        }, new Map())

        setAddressesMap(companies)
      }
    }
  })

  useEffect(() => {
    jobTypes.forEach((jt: JobType) => {
      // @ts-ignore
      jt.requiredFields?.forEach((rf: JobTypeRequiredFields) => {
        if (columns.find((column: any) => column.key === rf.name)) return

        if (rf.name?.startsWith('trip')) return

        columns.push({
          key: rf.name,
          title: startCase(rf.name || ''),
          render: (text: string, record: Job) => formatArrayString(record.details?.[rf.name || ''])
        })
      })

      setColumns(columns)
    })
  }, [columns, jobTypes])

  const expandedRowRender = useCallback(
    row => {
      const defaultSubColumns = [
        {
          title: '',
          width: '52px',
          key: 'indentation',
          className: 'xs-font'
        },
        {
          title: 'Trip No',
          dataIndex: 'sequence',
          key: 'sequence',
          render: (text: string, record: Trip, index: number) => {
            return index + 1
          }
        },
        {
          title: 'From',
          dataIndex: 'fromUuid',
          key: 'from',
          render: (text: string) => addressesMap.get(text)
        },
        {
          title: 'To',
          dataIndex: 'toUuid',
          key: 'to',
          render: (text: string) => addressesMap.get(text)
        }
      ]

      jobTypes.forEach((jobType: any) => {
        jobType.requiredFields.forEach((field: JobTypeRequiredFields) => {
          if (field.name?.startsWith('trip')) {
            const trimTrip = field.name?.slice(4)
            const title = startCase(field.name?.replace('trip', '') || '')
            const fieldKey = trimTrip.charAt(0).toLowerCase() + trimTrip.slice(1)
            defaultSubColumns.push({
              title,
              key: field.name,
              dataIndex: field.name,
              render: (text: string, record: any) => {
                return formatArrayString(record[fieldKey])
              }
            })
          }
        })
      })

      const data = row?.trips?.map((t: Trip, index: number) => ({ key: index, ...t }))

      return <Table size="small" columns={defaultSubColumns} dataSource={data} pagination={false} />
    },
    [addressesMap, jobTypes]
  )

  useEffect(() => {
    if (step === 2 && dataSource?.[0]?.trips?.length) {
      requestJobTypes(uniq(dataSource.map((job: Job) => job.type)))

      const companyUuids = dataSource.reduce((uuids: string, job: any) => {
        const tripCompanyUuids: any = []

        job.trips.forEach((trip: any) => {
          tripCompanyUuids.push(trip.fromCompanyUuid)
          tripCompanyUuids.push(trip.toCompanyUuid)
        })

        return uniq(uuids.concat(tripCompanyUuids.filter(Boolean)))
      }, [])

      getCompanies({
        variables: {
          statuses: ['activated'],
          uuids: companyUuids,
          limit: 50
        }
      })
    }
  }, [dataSource, step])

  if (error) {
    logger.error('JobsTable COMPANIES_QUERY error', error)
    respHandler(error, 'error')
  }

  if (isEmpty(addressesMap) || isEmpty(dataSource) || isEmpty(jobTypes)) {
    return <Spin />
  }

  return (
    <Table
      size="middle"
      rowKey={uuidV4}
      columns={columns}
      loading={loading}
      pagination={false}
      dataSource={dataSource}
      defaultExpandAllRows={true}
      expandedRowRender={expandedRowRender}
    />
  )
})

export default withApollo(JobsTable)
