import type { Incentive, Job, TransportJob, UpdateJobInput } from '@/types/graphql'

import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { ApolloClient, useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Col, Form, Row, Spin } from 'antd'

import { ActionItem, Actions } from '@/components/Manage/Styled'
import useJobTypes from '@/hooks/useJobTypes'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { QUERY_JOB, UPDATE_JOB, UPDATE_TRIP } from './job-trip-schema'
import JobFormDetails from './JobFormDetails'

interface LegFormDisplayProps {
  client?: ApolloClient<object>
  form: any
  value: any
  legsLoading: boolean
  incentives: Incentive[]
  incentiveLoading: boolean
  leg?: TransportJob
}

const JobContainerSealDisplay = memo((props: LegFormDisplayProps) => {
  const { client, form, leg, legsLoading } = props

  const { requestJobTypes, jobTypes } = useJobTypes(client)
  const { t } = useTranslation()

  const [updateJobMutation, { loading: loadingJob }] = useMutation(UPDATE_JOB, { client })
  const [updateTripMutation, { loading: loadingTrip }] = useMutation(UPDATE_TRIP, { client })
  const {
    loading,

    data: jobResults,

    error,

    refetch
  } = useQuery(QUERY_JOB, {
    skip: !leg?.jobUuid,
    client,
    variables: { uuid: leg?.jobUuid },
    fetchPolicy: 'cache-first'
  })

  const [editJobMode, setEditJobMode] = useState(true)
  const [jobFormSuccessMsg, setJobFormSuccessMsg] = useState('')
  const [jobFormError, setJobFormError] = useState('')
  const [job, setJob] = useState<Job>({})
  const [locLeg, setLocLeg] = useState<any>(leg)
  const [dynamicFields, setDynamicFields] = useState<any>()
  const [tripDynamicFields, setTripDynamicFields] = useState<any>()

  useEffect(() => {
    setDynamicFields(jobTypes[0]?.dynamicFields?.map(i => i?.key) || [])
    setTripDynamicFields(jobTypes[0]?.tripDynamicFields?.map(i => i?.key) || [])
  }, [jobTypes])

  useEffect(() => {
    if (jobResults?.job) {
      setJob(jobResults.job)
    }
  }, [jobResults])

  const onSwitchEditJobMode = useCallback(() => {
    setEditJobMode((state: boolean) => !state)
    setJobFormSuccessMsg('')
    setJobFormError('')
  }, [])

  const onUpdateJob = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      const jobDetails = {}
      const tripDetails = {}
      dynamicFields?.forEach(key => {
        if (key in values.job.details) {
          jobDetails[key] = values?.job.details[key]
        }
      })

      tripDynamicFields?.forEach(key => {
        if (key in values.trip.details) {
          tripDetails[key] = values?.trip.details[key]
        }
      })
      try {
        setJobFormSuccessMsg('')
        setJobFormError('')

        const updateObj: UpdateJobInput = {
          uuid: leg?.jobUuid,
          details: jobDetails
        }
        setJobFormSuccessMsg('Saving job details...')

        const jobRes = await updateJobMutation({ variables: { input: updateObj } })
        await updateTripMutation({
          variables: {
            input: {
              uuid: leg?.tripUuid,
              details: tripDetails
            }
          }
        })

        if (jobRes?.data?.updateJob?.uuid) {
          refetch()
          onSwitchEditJobMode()

          responseHandler('Successfully updated job.', 'success')
        } else {
          setJobFormSuccessMsg('')
          responseHandler('No job selected for update.', 'warning')
        }

        setLocLeg({ ...locLeg, tripSeal: values?.trip?.seal })
      } catch (error: unknown) {
        responseHandler(error, 'error')
        logger.error('UpdateLegForm updateJobMutation error', error)
        setJobFormSuccessMsg('')
        if (error instanceof Error) {
          setJobFormError(error.message)
        } else {
          setJobFormError(JSON.stringify(error))
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, leg, updateJobMutation])

  useEffect(() => {
    if (!job.type) return
    requestJobTypes([job.type])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.type])

  if (loading || loadingJob || loadingTrip) {
    return <Spin />
  }

  if (error) {
    return <Alert message={error.message} type="error" />
  }

  return (
    <Col xs={24} xl={24} span={8}>
      <JobFormDetails
        job={job}
        leg={locLeg}
        editMode={editJobMode}
        value={props.value}
        form={form}
        client={client}
      />

      <Row gutter={24}>
        <Col span={5} />
        <Col span={19}>
          {jobFormSuccessMsg && (
            <Alert message={jobFormSuccessMsg} type="success" style={{ fontSize: '12px' }} />
          )}
          {jobFormError && (
            <Alert message={jobFormError} type="error" style={{ fontSize: '12px' }} />
          )}
        </Col>
      </Row>

      <Actions>
        <ActionItem span={24}>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              icon={editJobMode ? '' : 'edit'}
              loading={legsLoading}
              onClick={onSwitchEditJobMode}
            >
              {editJobMode ? t('common.cancel') : t('common.edit')}
            </Button>
            {editJobMode && (
              <Button icon="check" loading={loadingJob} onClick={onUpdateJob}>
                {t('common.submit')}
              </Button>
            )}
          </Row>
        </ActionItem>
      </Actions>
    </Col>
  )
})

// @ts-ignore
export default withApollo(Form.create()(JobContainerSealDisplay))
