import type { BookingTableColumnProps } from 'App/components/Booking/ListView/ColumnBuilder'

import { useTranslation } from 'react-i18next'
import { ExportOutlined } from '@ant-design/icons'
import { Button, Dropdown, Typography } from 'antd'

import lazyLoad from 'App/utils/retryLazyLoad'

const DropDownContent = lazyLoad(() => import('./DropDownContent'))

type ExportBookingListProps = {
  query: any
  total: number
  columns: BookingTableColumnProps[]
}

const ExportBookingList = ({ total, query, columns }: ExportBookingListProps) => {
  const { t } = useTranslation()
  return (
    <Dropdown overlay={<DropDownContent total={total} query={query} columns={columns} />}>
      <Button className="border-none box-none">
        <ExportOutlined />
        <Typography.Text strong type="secondary">
          {t('index.export')}
        </Typography.Text>
      </Button>
    </Dropdown>
  )
}

export default ExportBookingList
