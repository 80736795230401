import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

import type { JobType } from 'App/types/graphql'
import { logger } from 'App/utils/logger'

const JOB_TYPES_QUERY = gql`
  query useJobTypes($codes: [String]) {
    jobTypes(codes: $codes) {
      rows {
        uuid
        code
        name
        defaultTripFormat
        tripDetails {
          type
          fromCompanyTypes
          toCompanyTypes
        }
        requiredFields {
          name
          control
        }
        unitOptions {
          units
          values
        }
        dynamicFields {
          key
          type
          query
          companyTypes
          control
          searchable
          multiple
          enumName
          customValues
          isMultiline
          isCapitalize
          isValidateContainerNumber
          driverAppUpdateable
        }
        tripDynamicFields {
          key
          type
          query
          companyTypes
          control
          searchable
          multiple
          enumName
          customValues
          isMultiline
          isCapitalize
        }
      }
    }
  }
`

const useJobTypes = (client: any) => {
  const [jobTypes, setJobTypes] = useState<JobType[]>([])

  const [getJobTypes, { data, loading, error }] = useLazyQuery(JOB_TYPES_QUERY, {
    client,
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    data?.jobTypes?.rows && setJobTypes(data.jobTypes.rows)
  }, [data])


  if (error) {
    logger.error('useJobTypes Error.', error)
  }

  const requestJobTypes = (codes?: string[]) => {
    getJobTypes({
      variables: { codes }
    })
  }

  return {
    requestJobTypes,
    loading,
    error,
    jobTypes
  }
}

export default useJobTypes
