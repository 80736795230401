import type { BookingsSearchInput } from '@/types/graphql'
import type { ApolloClient } from '@apollo/client'

import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import {
  CopyOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MinusCircleOutlined
} from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { Button, Layout, Menu, notification, Tooltip, Typography } from 'antd'
import { stringify } from 'query-string'

import BookingListView from '@/components/Booking/ListView'
import { BookingTableColumnProps, getColumns } from '@/components/Booking/ListView/ColumnBuilder'
import ExportBookingList from '@/components/Booking/ListView/Export'
import { BOOKINGS_MAIN_QUERY } from '@/components/Booking/schema'
import SideFilter from '@/components/Booking/SideFilters'
import { SingleLineTableContainer } from '@/components/Booking/Styled'
import UserFilter from '@/components/Booking/UserFilter'
import { getBookingQuery } from '@/components/Layouts/Search/helper'
import { useIsMobile } from '@/hooks/use-mobile'
import { DEPARTMENTS_SETTING, useBaseCompanySetting } from '@/hooks/useBaseCompanySetting'
import { useBookingStore } from '@/store/booking'
import { logger } from '@/utils/logger'
import notify from '@/utils/notify'
import responseHandler from '@/utils/responseHandler'
import { copyToClipboard, getSearchParamValue } from '@/utils/u'

const { Sider, Content, Header } = Layout
const { Text } = Typography

const SearchResult = memo(({ client }: { client: ApolloClient<object> }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const selectedBookingsTable = useBookingStore.use.selectedBookingsTable()
  const setSelectedBookingsTable = useBookingStore.use.setSelectedBookingsTable()

  const { setting } = useBaseCompanySetting(DEPARTMENTS_SETTING, {
    client,
    variables: { key: 'overviewPageSettings' },
    fetchPolicy: 'cache-first'
  })

  const [total, setTotal] = useState(0)
  const [results, setResults] = useState([])
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [loadingCopy, setLoadingCopy] = useState(false)
  const [collapsedDrawer, setCollapsedDrawer] = useState(isMobile)
  const [columns, setColumns] = useState<BookingTableColumnProps[]>(getColumns())
  const [query, setQuery] = useState<BookingsSearchInput>({
    q: '',
    sort: '',
    limit: 20,
    offset: 0,
    filter: {},
    _noSubGraph: true
  })

  const [getBookings, { loading, data, error }] = useLazyQuery(BOOKINGS_MAIN_QUERY, {
    client,
    variables: { input: query },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (isMobile) setCollapsedDrawer(true)
    else setCollapsedDrawer(false)
  }, [isMobile])

  useEffect(() => {
    if (setting?.[0]?.value) {
      setColumns(getColumns(JSON.parse(setting?.[0]?.value)))
    }
  }, [setting, selectedBookingsTable])

  useEffect(() => {
    const isAssigned = getSearchParamValue(history, 'isAssigned')
    const offset = Number(getSearchParamValue(history, 'offset')) || 0
    const limit = Number(getSearchParamValue(history, 'limit')) || 20

    const { graphqlFormat } = getBookingQuery(history, {
      __noSubGraph: true,
      isAssigned: isAssigned ? !!Number(getSearchParamValue(history, 'isAssigned')) : null,
      offset,
      limit
    })

    setQuery(graphqlFormat)
    getBookings()
  }, [getBookings, history, history.location.search])

  useEffect(() => {
    if (data?.bookingsSearchJson?.rows) {
      setResults(data.bookingsSearchJson.rows)
      setTotal(data.bookingsSearchJson?.pageInfo?.count ?? 0)
      setCurrentPage(
        (data.bookingsSearchJson?.pageInfo?.offset ?? 0) /
          (data.bookingsSearchJson?.pageInfo?.limit ?? 20)
      )
    }
  }, [data])

  const handlePageChange = useCallback(
    (page: number, pageSize?: number) => {
      const limit = pageSize ?? 20
      const offset = (page - 1) * limit

      if (limit + offset > 10000) {
        notification.error({
          message: 'You have exceeded query limit. Please narrow your search.',
          duration: 5
        })
        return
      }

      const { graphqlFormat, urlFormat } = getBookingQuery(history, {
        offset,
        limit,
        __noSubGraph: true
      })

      history.push(`/?${stringify(urlFormat)}`)

      setQuery({ ...query, ...graphqlFormat })
    },
    [query, history]
  )

  const handleSizeChange = useCallback(
    (current: number, size: number) => {
      setPageSize(size)
      handlePageChange(current, size)
    },
    [handlePageChange]
  )

  useEffect(() => {
    if (history.location.search) return

    const { urlFormat } = getBookingQuery(history, query)

    history.push(`/?${stringify(urlFormat)}`)
  }, [history, query])

  if (error) {
    responseHandler(error, 'error')
    logger.error('BookingsListView BOOKING_SEARCH_QUERY error', error)
  }

  const toggleSideDrawer = () => {
    setCollapsedDrawer(prev => !prev)
  }

  const handleCopy = async (copyAll: boolean) => {
    if (copyAll) {
      setLoadingCopy(true)
      const bookings: any[] = []
      const input = { ...query }
      input.offset = 0

      while (input.offset < total) {
        const { data } = await client.query({ query: BOOKINGS_MAIN_QUERY, variables: { input } })

        bookings.push(...(data.bookingsSearchJson?.rows ?? []))
        input.offset += input.limit ?? 20
      }

      const bookingNos = bookings.map(booking => booking.no || booking.uuid)
      await copyToClipboard(bookingNos.join(' '))
      notify(`${bookingNos.length} booking(s) copied to clipboard`, 'success')
      setLoadingCopy(false)
    } else {
      notify('Copying selected bookings to clipboard', 'load')
      await copyToClipboard(selectedBookingsTable.join(' '))
      notify(`${selectedBookingsTable.length} booking(s) copied to clipboard`, 'success')
    }
  }

  return (
    <SingleLineTableContainer>
      <Layout>
        <Sider
          collapsible
          trigger={null}
          collapsedWidth={0}
          collapsed={collapsedDrawer}
          onCollapse={toggleSideDrawer}
        >
          <Menu theme="light" mode="inline">
            {!collapsedDrawer && <SideFilter />}
          </Menu>
        </Sider>
        <Layout>
          <Header className="bg-white px-2">
            <div className="flex space-between items-center">
              <div className="space-x-4 flex">
                <span className="cursor-pointer" onClick={toggleSideDrawer}>
                  {collapsedDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </span>
                <div className="flex space-x-2">
                  <Typography.Text type="secondary" strong>
                    {t('index.filterByUsers')} :
                  </Typography.Text>
                  <UserFilter />
                </div>
              </div>
              <div className="flex items-center space-x-1">
                {selectedBookingsTable.length > 0 && (
                  <>
                    <Tooltip title="Copy selected bookings">
                      <Button type="dashed" size="small" onClick={() => handleCopy(false)}>
                        <CopyOutlined />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Clear selected bookings">
                      <Button
                        type="dashed"
                        size="small"
                        onClick={() => setSelectedBookingsTable([])}
                      >
                        <MinusCircleOutlined />
                      </Button>
                    </Tooltip>
                    <Text className="text-xs ml-4" type="secondary">
                      {selectedBookingsTable.length} Selected
                    </Text>
                    <Tooltip title="Select all bookings record">
                      <Button
                        className="border-none box-none"
                        loading={loadingCopy}
                        onClick={() => handleCopy(true)}
                      >
                        Select All {total}
                      </Button>
                    </Tooltip>
                  </>
                )}
                <ExportBookingList total={total} query={query} columns={columns} />
              </div>
            </div>
          </Header>
          <Content className="bg-off-white p-0">
            <BookingListView
              total={total}
              columns={columns}
              loading={loading}
              results={results}
              pageSize={pageSize}
              currentPage={currentPage}
              handleSizeChange={handleSizeChange}
              handlePageChange={handlePageChange}
            />
          </Content>
        </Layout>
      </Layout>
    </SingleLineTableContainer>
  )
})

export default withApollo(SearchResult)
